/* AI GENERATED CODE */
import React, { useEffect, useRef } from "react"

export default function Home() {
  const textElementRef = useRef(null)
  const flickerElementRef = useRef(null)
  const screenPowerElementRef = useRef(null)
  const crtScreenRef = useRef(null)

  // Animation state ref to maintain state without re-renders
  const animState = useRef({
    currentIndex: 0,
    charIndex: 0,
    isAnimating: false,
    timeouts: [] as any[],
    intervals: [] as any[],
  })

  useEffect(() => {
    const textElement = textElementRef.current
    const flickerElement = flickerElementRef.current
    const screenPowerElement = screenPowerElementRef.current
    const crtScreen = crtScreenRef.current

    // Function to clear all timeouts and intervals
    const clearAllTimers = () => {
      animState.current.timeouts.forEach((timerObj) => clearTimeout(timerObj.id))
      animState.current.intervals.forEach((timerObj) => clearInterval(timerObj.id))
      animState.current.timeouts = []
      animState.current.intervals = []
    }

    // Retro terminal marketing sequence for ACME.BOT
    // Each item displays up to 5 characters with varied speed and pause times
    const sequence = [
      // Intro
      { text: "ACME", speed: 100, pause: 800 },
      { text: ".", speed: 300, pause: 200 },
      { text: "BOT", speed: 100, pause: 1200 },
      { text: ">_<", speed: 40, pause: 2500 },

      // SEO focus
      { text: "SEO", speed: 100, pause: 700 },
      { text: "ROCK", speed: 80, pause: 900 },
      { text: "O_O", speed: 40, pause: 2000 },
      { text: "RANK", speed: 120, pause: 800 },
      { text: "#1", speed: 60, pause: 1500 },
      { text: "^_^", speed: 40, pause: 2700 },

      // Content creation
      { text: "WRITE", speed: 150, pause: 600 },
      { text: "POST", speed: 150, pause: 700 },
      { text: "FAST", speed: 200, pause: 1000 },
      { text: "AI", speed: 120, pause: 500 },
      { text: "MAGIC", speed: 80, pause: 1200 },
      { text: "*..*", speed: 40, pause: 2300 },

      // Research capabilities
      { text: "DEEP", speed: 100, pause: 700 },
      { text: "RSRCH", speed: 120, pause: 900 },
      { text: "(o_O)", speed: 40, pause: 2200 },
      { text: "DATA", speed: 150, pause: 800 },
      { text: "FACTS", speed: 130, pause: 1100 },

      // Illustrations
      { text: "PICS", speed: 100, pause: 700 },
      { text: "DIAGR", speed: 150, pause: 800 },
      { text: "DRAW", speed: 100, pause: 900 },
      { text: "WOW!", speed: 60, pause: 1300 },
      { text: "(・o・)", speed: 40, pause: 2800 },

      // Strategy
      { text: "PLAN", speed: 150, pause: 800 },
      { text: "STRATG", speed: 150, pause: 700 },
      { text: "=^.^=", speed: 40, pause: 2400 },
      { text: "COMPET", speed: 120, pause: 900 },
      { text: "BEAT", speed: 60, pause: 1600 },

      // Automation
      { text: "AUTO", speed: 100, pause: 700 },
      { text: "24/7", speed: 150, pause: 900 },
      { text: "ZZZZ", speed: 40, pause: 1200 }, // You sleep while bot works
      { text: "BUSY", speed: 130, pause: 800 },
      { text: "BEE", speed: 80, pause: 1100 },
      { text: "⊙_⊙", speed: 40, pause: 2600 },

      // Features
      { text: "HUMAN", speed: 150, pause: 600 },
      { text: "MULTI", speed: 150, pause: 700 },
      { text: "^.^", speed: 40, pause: 2200 },
      { text: "LANG", speed: 130, pause: 900 },
      { text: "BLOGS", speed: 130, pause: 700 },
      { text: "INTEG", speed: 150, pause: 1000 },
      { text: "CHECK", speed: 150, pause: 1300 },

      // Benefits
      { text: "SAVE", speed: 130, pause: 700 },
      { text: "TIME", speed: 130, pause: 900 },
      { text: "GROW", speed: 130, pause: 700 },
      { text: "BIZ", speed: 130, pause: 1000 },
      { text: "$$$$", speed: 60, pause: 1200 },
      { text: "OwO", speed: 40, pause: 2500 },

      // Call to action
      { text: "TRY", speed: 150, pause: 600 },
      { text: "NOW", speed: 150, pause: 800 },
      { text: ">w<", speed: 40, pause: 2300 },
      { text: "EASY", speed: 130, pause: 900 },
      { text: "WIN!", speed: 60, pause: 1500 },
      { text: "^O^", speed: 40, pause: 3000 },

      // Final emphasis
      { text: "AEO", speed: 130, pause: 700 }, // Answer Engine Optimization
      { text: "ERA", speed: 130, pause: 800 },
      { text: "ACME", speed: 150, pause: 600 },
      { text: ".", speed: 300, pause: 200 },
      { text: "BOT", speed: 150, pause: 1000 },
      { text: "*_*", speed: 40, pause: 2400 },
      { text: "<3", speed: 40, pause: 3000 },
    ]

    // Power on sequence
    function powerOn() {
      if (animState.current.isAnimating) return // Prevent multiple starts
      animState.current.isAnimating = true

      // Clear any existing animations
      clearAllTimers()

      const t1Id = setTimeout(() => {
        // Add "screen-on" class to trigger the transition
        crtScreen.classList.add("screen-on")

        // Add static and then start the text animation
        const t2Id = setTimeout(() => {
          // Quick random static characters
          displayStaticNoise()

          // Start the text animation after static
          const t3Id = setTimeout(() => {
            randomFlicker()
            addDistortion()
            typeNextWord()
            glitchText()
          }, 1000)

          animState.current.timeouts.push({ id: t3Id, name: "powerOn" })
        }, 500)

        animState.current.timeouts.push({ id: t2Id, name: "powerOn" })
      }, 800)

      animState.current.timeouts.push({ id: t1Id, name: "powerOn" })
    }

    // Display static noise effect with random characters
    function displayStaticNoise() {
      const chars = "!@#$%^&*()_+-={}[]|:;<>,.?/~`"
      let iterations = 0
      const maxIterations = 10

      // Clear any existing static animation
      animState.current.intervals = animState.current.intervals.filter(
        (timerObj) => {
          if (timerObj.name === "static") {
            clearInterval(timerObj.id)
            return false
          }
          return true
        }
      )

      const intervalId = setInterval(() => {
        const randomChars = []
        for (let i = 0; i < 5; i++) {
          randomChars.push(chars.charAt(Math.floor(Math.random() * chars.length)))
        }
        textElement.innerHTML = randomChars.join("")

        iterations++
        if (iterations >= maxIterations) {
          clearInterval(intervalId)
          textElement.innerHTML = ""
          // Remove this interval from the list
          animState.current.intervals = animState.current.intervals.filter(
            (timerObj) => timerObj.id !== intervalId
          )
        }
      }, 50)

      // Store the interval with its name
      animState.current.intervals.push({ id: intervalId, name: "static" })
    }

    // Simulate CRT flicker - less frequent than original
    function randomFlicker() {
      // Clear any existing flicker animation
      animState.current.timeouts = animState.current.timeouts.filter((timerObj) => {
        if (timerObj.name === "flicker") {
          clearTimeout(timerObj.id)
          return false
        }
        return true
      })

      const randomTime = Math.random() * 5000 // Longer delay between flickers
      const t1Id = setTimeout(() => {
        flickerElement.style.backgroundColor = "rgba(255,255,255,0.15)"
        flickerElement.style.opacity = Math.random() * 0.15 // Less intense flicker

        const t2Id = setTimeout(
          () => {
            flickerElement.style.opacity = 0
            randomFlicker()
          },
          20 + Math.random() * 60
        )

        // Store the timeout with its name
        animState.current.timeouts.push({ id: t2Id, name: "flicker" })

        // Remove t1 from the list
        animState.current.timeouts = animState.current.timeouts.filter(
          (timerObj) => timerObj.id !== t1Id
        )
      }, randomTime)

      // Store the timeout with its name
      animState.current.timeouts.push({ id: t1Id, name: "flicker" })
    }

    // Simulate analog distortion
    function addDistortion() {
      // Clear any existing distortion
      animState.current.intervals = animState.current.intervals.filter(
        (timerObj) => {
          if (timerObj.name === "distortion") {
            clearInterval(timerObj.id)
            return false
          }
          return true
        }
      )

      const intervalId = setInterval(() => {
        const randomOffset = Math.random() * 1.5 - 0.75
        textElement.style.transform = `translateX(${randomOffset}px) translateY(${randomOffset}px)`
      }, 40)

      // Store the interval with its name
      animState.current.intervals.push({ id: intervalId, name: "distortion" })
    }

    // Type the current word
    function typeNextWord() {
      // Use the ref values
      const current = sequence[animState.current.currentIndex]
      animState.current.charIndex = 0

      // Clear before typing new word
      if (textElement) {
        textElement.innerHTML = '<span class="cursor"></span>'
      }

      // Type the next word character by character
      typeChar(current)
    }

    // Type each character of the current word
    function typeChar(wordObj: any) {
      // Clear any existing typing timeouts
      animState.current.timeouts = animState.current.timeouts.filter((timerObj) => {
        if (timerObj.name === "typing") {
          clearTimeout(timerObj.id)
          return false
        }
        return true
      })

      if (animState.current.charIndex < wordObj.text.length) {
        if (textElement) {
          textElement.innerHTML =
            wordObj.text.substring(0, animState.current.charIndex + 1) +
            '<span class="cursor"></span>'
        }
        animState.current.charIndex++

        const t1Id = setTimeout(() => typeChar(wordObj), wordObj.speed)
        animState.current.timeouts.push({ id: t1Id, name: "typing" })
      } else {
        // Word complete - pause then go to next word
        const t2Id = setTimeout(() => {
          animState.current.currentIndex =
            (animState.current.currentIndex + 1) % sequence.length

          // Randomly show static between some words
          if (Math.random() < 0.3) {
            displayStaticNoise()
            const t3Id = setTimeout(typeNextWord, 500)
            animState.current.timeouts.push({ id: t3Id, name: "typing" })
          } else {
            typeNextWord()
          }
        }, wordObj.pause)

        animState.current.timeouts.push({ id: t2Id, name: "typing" })
      }
    }

    // Occasionally glitch the text
    function glitchText() {
      // Clear any existing glitch interval
      animState.current.intervals = animState.current.intervals.filter(
        (timerObj) => {
          if (timerObj.name === "glitch") {
            clearInterval(timerObj.id)
            return false
          }
          return true
        }
      )

      const intervalId = setInterval(() => {
        if (Math.random() < 0.1 && textElement) {
          // 10% chance of glitch
          const currentText = textElement.innerText.replace("\uFEFF", "") // Remove cursor
          if (currentText.length > 0) {
            let glitchedText = currentText.split("")

            // Randomly replace a character with a special character
            const randomIndex = Math.floor(Math.random() * glitchedText.length)
            const glitchChars = "!@#$%^&*()_+-="
            glitchedText[randomIndex] = glitchChars.charAt(
              Math.floor(Math.random() * glitchChars.length)
            )

            // Store the original text
            const originalText = currentText

            // Show glitched text briefly
            textElement.innerHTML =
              glitchedText.join("") + '<span class="cursor"></span>'

            // Restore original after a short time
            const tId = setTimeout(() => {
              if (textElement) {
                textElement.innerHTML = originalText + '<span class="cursor"></span>'
              }
            }, 100)

            // Store the timeout with its name
            animState.current.timeouts.push({ id: tId, name: "glitchEffect" })
          }
        }
      }, 2000)

      // Store the interval with its name
      animState.current.intervals.push({ id: intervalId, name: "glitch" })
    }

    // Start animation
    powerOn()

    // Cleanup on unmount
    return () => {
      clearAllTimers()
      animState.current.isAnimating = false
    }
  }, []) // Run once on mount

  return (
    <>
      {/*eslint-disable-next-line react/no-unknown-property*/}
      <style jsx>
        {`
          .monitor-container {
            position: relative;
            width: 360px;
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform-origin: center;
          }

          /* Tiny screen! */
          .crt-screen {
            position: absolute;
            width: 56px;
            height: 48px;
            top: 30%;
            left: 39%;
            background-color: #322539;
            border-radius: 4px;
            overflow: hidden;
            z-index: 2;
          }

          .monitor-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }

          .crt-content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .crt-text {
            color: #62c0c4;
            font-size: 16px;
            font-weight: bold;
            text-shadow:
              0 0 3px #62c0c4,
              0 0 6px rgba(98, 192, 196, 0.8);
            text-align: center;
            z-index: 10;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 0px;
            width: 100%;
            padding: 0 2px;
            box-sizing: border-box;
          }

          /* Scanlines effect */
          .scanlines {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to bottom,
              transparent 50%,
              rgba(0, 0, 0, 0.3) 50%
            );
            background-size: 100% 2px;
            z-index: 15;
            pointer-events: none;
            opacity: 0.4;
          }

          /* CRT flicker */
          .crt-flicker {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            opacity: 0;
            z-index: 14;
            pointer-events: none;
          }

          /* Screen glow */
          .screen-glow {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
              ellipse at center,
              rgba(98, 192, 196, 0.15) 0%,
              rgba(0, 0, 0, 0.2) 80%
            );
            z-index: 13;
            pointer-events: none;
          }

          /* Screen glare effect */
          .screen-glare {
            position: absolute;
            top: -30%;
            left: -30%;
            width: 100%;
            height: 100%;
            background: radial-gradient(
              ellipse at center,
              rgba(255, 255, 255, 0.4) 1%,
              rgba(255, 255, 255, 0.1) 50%,
              rgba(255, 255, 255, 0) 70%
            );
            border-radius: 50%;
            transform: rotate(-40deg);
            z-index: 17;
            pointer-events: none;
            opacity: 0.7;
          }

          /* CRT distortion */
          .crt-content::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
              ellipse at center,
              transparent 0%,
              rgba(0, 0, 0, 0.3) 100%
            );
            pointer-events: none;
            z-index: 12;
          }

          /* Vignette effect */
          .vignette {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.7);
            z-index: 11;
            pointer-events: none;
          }

          /* Cursor effect */
          .cursor {
            display: inline-block;
            width: 5px;
            height: 16px;
            background-color: #62c0c4;
            vertical-align: middle;
            margin-left: 2px;
            animation: blink 0.8s infinite;
          }

          @keyframes blink {
            0%,
            49% {
              opacity: 1;
            }
            50%,
            100% {
              opacity: 0;
            }
          }

          /* Multiple power LEDs */
          .power-led {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-shadow: 0 0 5px currentColor;
            z-index: 3;
            animation: led-pulse 2s infinite;
          }

          .power-led:nth-child(1) {
            bottom: 22%;
            right: 30%;
            background-color: #f00;
            animation-delay: 0s;
          }

          .power-led:nth-child(2) {
            bottom: 22%;
            right: 35%;
            background-color: #ff0;
            animation-delay: 0.7s;
          }

          .power-led:nth-child(3) {
            bottom: 22%;
            right: 40%;
            background-color: #0f0;
            animation-delay: 1.4s;
          }

          @keyframes led-pulse {
            0%,
            100% {
              opacity: 1;
            }
            50% {
              opacity: 0.6;
            }
          }

          /* Screen power on/off effect */
          .screen-power {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: 20;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            pointer-events: none;
          }

          .screen-on .screen-power {
            opacity: 0;
          }

          /* Added static effect */
          .static-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/images/landing/crt.png);
            opacity: 0.05;
            z-index: 16;
            pointer-events: none;
          }
          @media (min-width: 680px) {
            .monitor-container {
              width: 460px;
              height: 320px;
            }
            .crt-screen {
              width: 72px;
              height: 60px;
              top: 31%;
              left: 39%;
            }
            .crt-text {
              font-size: 16px;
            }
          }
        `}
      </style>

      <div className="monitor-container">
        {/* Placeholder monitor image */}
        <div
          className="monitor-image"
          style={{
            backgroundColor: "transparent",
            borderRadius: "20px",
            backgroundImage: "url(/images/landing/crt.png)",
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>

        {/* The tiny screen where our content appears */}
        <div className="crt-screen" ref={crtScreenRef}>
          <div className="crt-content">
            <div className="crt-text" ref={textElementRef}></div>
            <div className="scanlines"></div>
            <div className="crt-flicker" ref={flickerElementRef}></div>
            <div className="screen-glow"></div>
            <div className="screen-glare"></div>
            <div className="vignette"></div>
            <div className="static-effect"></div>
            <div className="screen-power" ref={screenPowerElementRef}></div>
          </div>
        </div>
      </div>
    </>
  )
}
